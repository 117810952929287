import ajax from '@/utils/ajax.js'

/**
 * 发票类目 列表
 * @param {*} params 
 * @returns 
 */
export function getInvoiceCategoryList(params) {
  return ajax.post('/InvoiceGoodsType/getList',params)
}

/**
 * 发票类目 详情
 * @param {*} params 
 * @returns 
 */
 export function getInvoiceCategoryDetail(params) {
  return ajax.post('/InvoiceGoodsType/detail',params)
}


/**
 * 发票类目 新增
 * @param {*} params 
 * @returns 
 */
export function addInvoiceCategory(params) {
  return ajax.post('/InvoiceGoodsType/add',params)
}
/**
 * 发票类目 编辑
 * @param {*} params 
 * @returns 
 */
export function updateInvoiceCategory(params) {
  return ajax.post('/InvoiceGoodsType/update',params)
}


/**
 * 城市个性化-发票类目 列表
 * @param {*} params 
 * @returns 
 */
export function getFinanceGoodsInvoiceList(params) {
  return ajax.post('/FinancialAccountInvoiceGoodsType/getList',params)
}

/**
 * 城市个性化-发票类目 编辑
 * @param {*} params 
 * @returns 
 */
export function setFinanceGoodsInvoice(params) {
  return ajax.post('/FinancialAccountInvoiceGoodsType/edit',params)
}


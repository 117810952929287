<template>
  <a-modal :visible.sync="show"
           title="选择商品设置发票类目"
           destroyOnClose
           width="800px"
           :footer="null"
           @cancel="$emit('update:show', false)">
    <div class="modal-search-bar">
      <a-form-model layout="horizontal"
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    ref="searchRef"
                    :model="searchForm">
        <!-- 第一步 -->
        <div v-if="activeStep==1">
          <div class="flex justify-between items-center">
            <a-form-model-item prop="type" label="分类" 
                class="mb-1" style="flex:2;">
              <a-select v-model="searchForm.type"
                        placeholder="产品属性"
                        style="width:100%;"
                        @change="handlerSearch">
                <a-select-option value>全部分类</a-select-option>
                <a-select-option v-for="item in Object.keys(goodsType)"
                                 :value="item"
                                 :key="item">{{goodsType[item]}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item prop="goods_name" label="商品名称" 
                class="mb-1" style="flex:3;">
              <a-input allowClear placeholder="商品名称"
                       v-model="searchForm.goods_name"
                       @keyup.enter="handlerSearch"></a-input>
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{span: 24}" class="mb-1">
              <a-button @click="handlerSearch" type="primary">搜索</a-button>
            </a-form-model-item>
          </div>
          <base-table ref="goodsTable"
                  rowKey="goods_id"
                  :columnsData="columns"
                  :row-selection="rowSelection"
                  :tableData="tableData"
                  :total="total"
                  :getListFunc="initData"
                  :page.sync="searchForm.page"
                  :customHeight="300"
                  :pageSize.sync="searchForm.page_count">
            <div slot="footer">已选 <span class="text-red-500">{{ rowSelection.selectedRowKeys.length }}</span> 项</div>
          </base-table>
        </div>
        
        <!-- 第二步 -->
        <div v-if="activeStep==2" style="min-height:200px;">
          <a-form-item style="margin: 10px 0;" label="发票类目">
            <a-select style="width:350px;" placeholder="请选择发票类目" v-model="invoice_category_id">
              <a-select-option v-for="(item, index) in invoiceCategoryList"
                   :value="item.id"
                   :key="index">{{item.name}}</a-select-option>
            </a-select>
          </a-form-item>
        </div>
      </a-form-model>
    </div>

    <div style="text-align:center;margin:10px;">
      <template v-if="activeStep==1">
        <a-button @click="handlerNext">下一步</a-button>
      </template>
      <template v-if="activeStep==2">
        <a-button class="mr-8" @click="handlerPre">上一步</a-button>
        <a-button type="primary" @click="handlerOK">提交</a-button>
      </template>
    </div>
  </a-modal>
</template>

<script>
import { formatGoodsType, goodsType } from "@/utils/type"
import { getGoodsList } from "@/api/goods";
import { 
  getInvoiceCategoryList,
  setFinanceGoodsInvoice
} from "@/api/auto-invoice";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectRows: {
      type: Array,
      default: () => [],
    },

    financialAccountId:{
      type: [String,Number],
      default: ''
    }
  },
  data () {
    return {
      goodsType,
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },

      activeStep: 1,

      invoice_category_id: undefined,
      invoiceCategoryList: [],

      searchForm: {
        goods_name: "",
        type: "",
        status: 1,
        page: 1,
        page_count: 40,
      },
      columns: [
        {
          title: "产品属性",
          dataIndex: "type",
          align: "center",
          width: 90,
          slots: {
            customRender: 'type'
          },
          slotsType: 'format',
          slotsFunc: (val) => formatGoodsType(val)
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
          width: "40%",
        },
        {
          title: '小程序门店在售',
          dataIndex: 'is_on_sale_shop',
          align: 'center',
          width: 100,
          slots: {
            customRender: 'is_on_sale_shop'
          },
          slotsType: 'format',
          slotsFunc: (val) => {return val==2?"是":""}
        },
        {
          title: '小程序商城在售',
          dataIndex: 'is_on_sale_mall',
          align: 'center',
          width: 100,
          slots: {
            customRender: 'is_on_sale_mall'
          },
          slotsType: 'format',
          slotsFunc: (val) => {return val==2?"是":""}
        },
        {
          title: '官网在售',
          dataIndex: 'is_on_sale_website',
          align: 'center',
          width: 100,
          slots: {
            customRender: 'is_on_sale_website'
          },
          slotsType: 'format',
          slotsFunc: (val) => {return val==2?"是":""}
        },
      ],
      tableData: [],
      total: 0,

      rowSelection: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.goods_id);
          } else {
            this.rowSelection.selectedRowKeys =
              this.rowSelection.selectedRowKeys.filter(
                (el) => el != record.goods_id
              );
          }
        },
        onSelectAll: (selected, selectedRows) => {
          if (selected) {
            this.rowSelection.selectedRowKeys = selectedRows.map(
              (el) => el.goods_id
            );
          } else {
            this.rowSelection.selectedRowKeys = [];
          }
        },
      },
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    async initData () {
      this.rowSelection.selectedRowKeys = []

      const { data, code } = await getGoodsList(this.searchForm)
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },
    // 保存
    handlerOK () {
      if(this.rowSelection.selectedRowKeys.length){
        const goods_ids = this.rowSelection.selectedRowKeys.join(",")
        let item
        if(this.invoice_category_id){
          item = this.invoiceCategoryList.find(el=>el.id==this.invoice_category_id)
          if(!item){
            this.$message.info("请选择发票类目")
            return
          }
        }
        
        let _this = this
        this.$confirm({
          title: "提示",
          content: '确定将所选产品设置为 '+item.name+" ？",
          okText: "确认",
          cancelText: "取消",
          onOk() {
            _this.setInvoice(goods_ids)
          },
        })
      }
    },

    async setInvoice(goods_ids){
      const {code} = await setFinanceGoodsInvoice({
        financial_account_id: this.financialAccountId,
        invoice_goods_type_id: this.invoice_category_id,
        goods_ids: goods_ids
      })
      if(code == 0){
        this.$emit("ok")
        this.$emit("update:show", false)
      } 
    },
    handlerSearch () {
      this.searchForm.page = 1
      this.initData()
    },
    resetForm () {
      this.searchForm.goods_name = ""
      this.searchForm.type = ""
      this.searchForm.page = 1
      this.searchForm.page_count = 30
      this.$refs.searchRef.resetFields()
      this.initData()
    },

    async handlerNext(){
      this.activeStep = 2
      if(!this.invoiceCategoryList.length){
        const { data, code } = await getInvoiceCategoryList()
        if (code === 0) {
          this.invoiceCategoryList = data.list
        }
      } 
    },
    handlerPre(){
      this.activeStep = 1
    },

  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>
<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline" ref="searchRef">
        <a-form-model-item prop="financial_account_id" label="财务账户">
          <a-select
            style="width: 320px"
            placeholder="请选择账户"
            v-model="searchForm.financial_account_id"
            @change="changeAccount"
          >
            <a-select-option
              v-for="(item, index) in manageFinanceList"
              :key="index"
              :label="item.name"
              :value="item.id"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
    <base-table
      ref="tableRef"
      rowKey="id"
      :columnsData="columns"
      :tableData="tableData"
      :total="total"
      :row-selection="rowSelection"
      :getListFunc="handlerSearch"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">城市个性化发票类目</h4>
          <a-button @click="addGoodsInvoice">设置个性化类目</a-button>
        </div>
      </template>
    </base-table>
    <choose-goods-modal
      v-if="isShowChooseGoods"
      :show.sync="isShowChooseGoods"
      :financialAccountId="searchForm.financial_account_id"
      @ok="initData"
    />
  </div>
</template>

<script>
import { 
  isFinanceManage, 
  getFinanceList
} from "@/utils/authData.js"
import { formatGoodsType } from "@/utils/type";
import {
  getFinanceGoodsInvoiceList
} from "@/api/auto-invoice"
import chooseGoodsModal from "./components/choose-goods-modal.vue";

export default {
  components: { 
    chooseGoodsModal
  },

  data() {
    return {
      isFinanceManage: isFinanceManage(),
      manageFinanceList: [],

      columns: [ 
        {
          title: '商品属性',
          dataIndex: 'goods_type',
          align: 'center',
          slots: {
            customRender: 'goods_type'
          },
          width: 90,
          slotsType: 'format',
          slotsFunc: (val) => formatGoodsType(val)
        },
        {
          title: '商品名',
          dataIndex: 'goods_name',
          align: 'center'
        },
        {
          title: '发票类目',
          dataIndex: 'invoice_goods_type_name',
          align: 'center'
        },
        {
          title: '发票类目编码',
          dataIndex: 'invoice_goods_type_code',
          align: 'center'
        },
        {
          title: '税率(一般纳税人)',
          dataIndex: 'invoice_goods_type_tax_rate_1',
          align: 'center',
          width: 140,
        },
        {
          title: '税率(小规模)',
          dataIndex: 'invoice_goods_type_tax_rate_2',
          align: 'center',
          width: 120,
        },
      ],

      searchForm: {
        financial_account_id: undefined,
        page: 1,
        page_count: 20,
      },
      total: 0,
      tableData: [],

      isShowChooseGoods: false,

      rowSelection: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.id);
          } else {
            this.rowSelection.selectedRowKeys =
              this.rowSelection.selectedRowKeys.filter(
                (el) => el != record.id
              );
          }
        },
        onSelectAll: (selected, selectedRows) => {
          if (selected) {
            this.rowSelection.selectedRowKeys = selectedRows.map(
              (el) => el.id
            );
          } else {
            this.rowSelection.selectedRowKeys = [];
          }
        },
      },
    };
  },

  async mounted() {
    this.manageFinanceList = await getFinanceList(true)

    if(this.manageFinanceList.length == 1){
      this.searchForm.financial_account_id = this.manageFinanceList[0].id
      this.handlerSearch()
    }
  },

  methods: {
    async initData() {
      if(!this.searchForm.financial_account_id){
        this.$message.info("请选择财务账户")
        return
      }

      this.rowSelection.selectedRowKeys = [];
      this.tableData = []
      const { code, data } = await getFinanceGoodsInvoiceList(this.searchForm)
      if(code == 0){
        this.tableData = data.list
        this.total = data.total_count;
      }
    },

    addGoodsInvoice(){
      if(!this.searchForm.financial_account_id){
        this.$message.info("请选择财务账户")
        return
      }

      this.isShowChooseGoods = true;
    },

    handlerSearch() {
      if(!this.searchForm.financial_account_id){
        this.$message.info("请选择财务账户")
        return
      }

      this.searchForm.page = 1
      // this.searchForm.page_count = 20
      this.initData();
    },
    changeAccount() {
      this.handlerSearch();
    },

  },
};
</script>

<style lang="scss" scoped>
</style>